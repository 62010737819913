<template>
  <div class="detail_faq_wrap">
    <div class="detail_notice_title">
      <span>{{$t('detail_faq')}}</span>
    </div>
    <div class="detail_notice_content">
      <div class="dn_header">
        <div class="dn_1">{{ boardData.created_at }} / {{$t('admin_write')}}  </div>
        <div class="dn_2">[{{ boardData.Category.bc_name }}] {{boardData.wr_subject}}</div>
      </div>
      <div class="dn_content ql-editor" v-html="replaceBr(boardData.wr_content)">
      </div>
    </div>
    <div class="detail_btn">
      <button @click="backList()">{{$t('list_view')}}</button>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
import util from "@/mixins/util";
export default {
  name: "BoardDetailFaqLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    boardData: {
      type: Object,
      default: () => {
        return []
      }
    },
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    backList(){
      this.$router.back();
    },
    replaceBr(content) {
      if (util.isEmpty(content)) {
        return '';
      }
      return content.replace(/(\n|\r\n)/g, '<br>');
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
